@import '/src/Variable.scss';

.comment-list {
  height: 100%;
  .ant-spin-nested-loading {
    height: 92%;
    .ant-spin-container {
      height: 100%;
      display: flex;
      .ant-list-items {
        width: 100%;
        margin-top: auto;

        .chatBubbleArea {
          width: 100%;
          .ant-row {
            .chatBubbleLayout {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              margin: 0 30px 15px auto;
              max-width: 60%;

              .replyBtn {
                margin-left: auto;
                font-size: 11.5px;
                color: $black-500;
                height: 22px;
                padding: 0;
                display: flex;
                align-items: center;
                svg {
                  font-size: 14px;
                  margin: 0 2px 0 0;
                }
              }
              .chatBubble {
                display: flex;
                justify-content: flex-end;
                border-radius: $border-radius-md;
                padding: 10px 16px;
                color: $white;
                position: relative;
                word-break: break-word;
                a {
                  word-break: break-all;
                }
                img {
                  max-width: 600px;
                  min-height: 125px;
                  max-height: 200px;
                  border-radius: $border-radius-md;
                }
                .starMsg {
                  position: absolute;
                  bottom: -2px;
                  right: 3px;
                  svg {
                    font-size: 12px;
                    cursor: pointer;
                    &.star {
                      color: $white;
                      &.stared {
                        color: $yellow;
                      }
                    }
                  }
                }
              }
              .chatBubbleTime {
                margin: 3px 0 0;
                font-size: 11.5px;
                color: $gray-text;
              }
            }

            .replyUserLayout {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              margin: 0 30px 15px auto;
              max-width: 60%;

              .replyBtn {
                margin-left: auto;
                font-size: 11.5px;
                color: $black-500;
                height: 22px;
                padding: 0;
                display: flex;
                align-items: center;
                svg {
                  font-size: 14px;
                  margin: 0 2px 0 0;
                }
              }
              .chatBubble {
                display: flex;
                justify-content: flex-end;
                // margin-left: auto;
                // background-color: $primary;
                border-radius: $border-radius-md;
                padding: 10px 16px;
                color: $white;
                position: relative;
                word-break: break-word;
                a {
                  word-break: break-all;
                }
                img {
                  max-width: 600px;
                  min-height: 125px;
                  max-height: 200px;
                  border-radius: $border-radius-md;
                }
                .starMsg {
                  position: absolute;
                  bottom: -2px;
                  right: 3px;
                  svg {
                    font-size: 12px;
                    cursor: pointer;
                    &.star {
                      color: $white;
                      &.stared {
                        color: $yellow;
                      }
                    }
                  }
                }
              }
              .chatBubbleTime {
                margin: 3px auto 0 0;
                font-size: 11.5px;
                color: $gray-text;
              }
            }

            .replyUserLayout {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              margin: 0 auto 15px 30px;

              .chatBubble {
                display: flex;
                justify-content: flex-start;
                background-color: $white;
                color: $black;
                .starMsg {
                  position: absolute;
                  bottom: -2px;
                  right: 3px;
                  svg {
                    &.star {
                      color: $black-400;
                      &.stared {
                        color: $yellow;
                      }
                    }
                  }
                }
              }
              .chatBubbleTime {
                margin: 3px 0 0;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .comment-list {
    .ant-spin-nested-loading {
      .ant-spin-container {
        .ant-list-items {
          .chatBubbleArea {
            .ant-row {
              .chatBubbleLayout {
                max-width: 70%;
                margin: 0 20px 4px auto;

                .chatBubble {
                  font-size: 13px;
                  padding: 10px 14px;
                }
                .chatBubbleTime {
                  margin: 3px 0 0 auto;
                }
                .chatBubbleTime {
                  font-size: 11px;
                }
              }
              .replyUserLayout {
                margin: 0 auto 5px 20px;

                .chatBubble {
                  font-size: 13px;
                  padding: 10px 14px;
                }
                .chatBubbleTime {
                  font-size: 11px;
                }
              }
            }
          }
        }
      }
    }
  }
}
