@import '/src/Variable.scss';

.buddyListLayoutSider {
  display: flex;
  flex-direction: row;
  border-right: 1px solid $card-border;
  // height: calc(100vh - 100px);
  .buddiesListHeader,
  .ant-page-header {
    background-color: $gray-bg;
    border: 0.6375px solid rgba($card-border, 0.25);
    border-top: unset;
    display: flex;
    align-items: center;
    width: 370px;
    padding: 20px 10px 17px 24px;
    min-height: 80px;
    max-height: 80px;
    .buddiesSearchInput {
      display: flex;
      border-radius: $border-radius-md;
      width: 294px;
      height: 42px;
      padding: 0 0 0 13px;
      text-indent: 20px;
      border: unset;
      input {
        border-radius: $border-radius-md;
      }
      .ant-input-prefix {
        svg {
          color: $gray-text;
          margin: 0 7px 0 0;
        }
      }
    }
    .menuButtonBuddie {
      display: flex;
      justify-items: center;
      box-shadow: none;
      border: unset;
      padding: 0;
      height: auto;
      margin: 0 0 0 auto;
      background: unset;
      .menuIconIn {
        color: black;
      }
    }
  }
  .myChatBuddiesView {
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 220px);
    overflow: auto;
    .buddyListBody {
      .ant-list-items {
        .listLayout {
          min-height: 84px;
          max-height: 84px;
          padding: 14px 24px;
          align-items: flex-start;
          border-bottom: 1px solid rgba($card-border, 0.35);
          cursor: pointer;
          &:hover {
            background-color: rgba($card-border, 0.23);
          }
          .buddiesInfo {
            display: flex;
            align-items: center;
            .ant-list-item-meta-avatar {
              .ant-avatar-image {
                width: 53px;
                height: 53px;
              }
            }
            .ant-list-item-meta-content {
              .ant-list-item-meta-title {
                font-size: 19px;
                line-height: 22px;
                font-weight: 700;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                .buddieName {
                  color: $black;
                }
              }
              .ant-list-item-meta-description {
                margin: 4px 0 0 0;
                .lastMessagePart {
                  font-size: 14px;
                  line-height: 16px;
                  color: #555;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }
          }
          .timeTagLayout {
            margin: 5px 0 0 0;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            .lastChatDate {
              font-size: 14px;
              line-height: 16px;
              color: $gray-text;
              margin: 0;
            }
            .unreadCount {
              min-width: 19px;
              // max-width: 19px;
              min-height: 19px;
              // max-height: 19px;
              margin: 8px 0 0 0;
              padding: 0;
              font-size: 11px;
              line-height: 14px;
              color: $white;
              text-align: center;
              background-color: $green;
              border: unset;
              border-radius: $border-radius-sm;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          &.active {
            // background-color: $primary;
            .buddieName {
              color: $white !important;
            }
            .lastMessagePart {
              color: $white !important;
              opacity: 0.8;
            }
            .lastChatDate {
              color: $white !important;
              opacity: 0.8;
            }
          }
        }
      }
      // &.staredMsgBody {
      //     .ant-spin-nested-loading {
      //         .ant-spin-container {
      //             .ant-list-items {
      //                 .listLayout {
      //                     .senderInfoArea {
      //                         .senderInfo {
      //                             font-size: 14px;
      //                             color: $black-500;
      //                             font-weight: 400;
      //                         }
      //                     }
      //                     .iconRemove {

      //                     }
      //                 }
      //             }
      //         }
      //     }
      // }
    }
  }
}

@media only screen and (max-width: 768px) {
  .buddyListLayoutSider {
    .ant-spin-nested-loading {
      width: 100%;
      .ant-spin-container {
        .buddiesListHeader {
          width: 100%;
        }
        .myChatBuddiesView {
          height: calc(100vh - 150px);
        }
      }
    }
  }
}

// @media only screen and (max-width: 768px) {
//     .width_0px{
//         display: none !important;
//         background-color: green !important;
//     }

//     .buddyListLayoutSider{
//         display: flex;
//         flex-direction: row;
//         max-width: 100vw;

//     .selectedListBuddy {
//         backgroundColor:'#1890ff';
//         color:#fafafa;
//         borderRadius:10
//     }
//     .lastMessagePart{
//         white-space: nowrap;
//         overflow: hidden;
//         text-overflow: ellipsis;
//         color:#010101;

//     }

//     .listLayout{
//         padding: 20px;
//         align-items: baseline;
//     }

//     .buddieName{
//         font-weight: bolder;
//         font-size: large;
//     }

//     }

//     .buddiesListHeader{
//         background-color: #f1eeee;
//         box-shadow: rgba(0, 0, 0, 0.2) 80px 80px 84px;
//         display: flex;
//         align-items: center;
//         height: 75px;
//         width: 100vw;

//     }
//     .myChatBuddiesView{
//         background-color: rgb(255, 255, 255);
//         display: flex;
//         flex-direction: column;
//         width: 100vw;
//         height: calc(100vh - 193px);

//         overflow: auto;

//     }
// }
