@import '/src/Variable.scss';

.buddieChatAreaLayout {
  .chatEmpty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    .ant-empty-image {
      img {
        height: 100px;
      }
    }
    .ant-empty-description {
      font-size: 26px;
      line-height: 32px;
      font-weight: 700;
      color: $black;
      margin: 28px 0 0 0;
    }
  }
  .chatArea {
    .ant-layout-content {
      .ant-row {
        .buddieHeader {
          width: 100%;
          min-height: 80px;
          max-height: 80px;
          background-color: $gray-bg;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 15px 23px;
          // box-shadow: rgba(0, 0, 0, 0.2) 80px 80px 84px;
          .buddieHeaderArea {
            display: flex;
            align-items: center;
            .backToBuddyList {
              font-size: 28px;
              color: #b0a9a9;
              margin: 0 10px 0 -10px;
              cursor: pointer;
            }
            img {
              border-radius: 150px;
              height: 50px;
              width: 50px;
              object-fit: cover;
            }
            .buddieTitleArea {
              margin: 0 0 0 15px;
              .buddieTitle {
                font-size: 19px;
                line-height: 22px;
                font-weight: 700;
              }
              .buddieReplyTime {
                font-size: 13px;
                line-height: 14px;
                color: $gray-text;
                margin: 3px 0 0 0;
              }
            }
          }
        }
        .chatBody {
          background-color: $chat-bg;
          background: $chat-bg url('../../../../assets/images/chat-bg.svg');
          padding: 0%;
          min-height: calc(100vh - 227px); /* 278px / 215px */
          max-height: calc(100vh - 227px);
          overflow-y: scroll;
        }
      }
      .chatInputArea {
        .inputArea {
          background-color: $gray-bg;
          display: flex;
          align-items: center;
          width: 100%;
          height: 77px;
          padding: 15px 28px 15px 26px;
          .inputItems {
            display: flex;
            width: 100%;
            height: 42px;
            input {
              border-radius: $border-radius-md;
            }
            .btnArea {
              display: flex;
              align-items: center;
              justify-content: space-evenly;
              .ant-upload-select-text {
                .ant-upload {
                  .attachmentButton {
                    background-color: unset;
                    border: unset;
                    padding: 0 5px;
                    margin: 0 7px;
                    svg {
                      transform: rotate(-45deg);
                      font-size: 18px;
                      color: $gray-text;
                    }
                  }
                }
              }
              .btn-primary {
                min-width: 74px;
              }
              .send-button {
                display: flex;
                align-items: center;
                border: 1px solid $primary;
                border-radius: $border-radius-md;
                padding: 6px 9px;
                font-size: 14px;
                line-height: 18px;
                font-weight: 400;
                color: $white;
                min-height: 42px;
              }
            }
          }
        }
      }
    }
  }
}
.drawerRight {
  .ant-drawer-mask {
    background: unset;
  }
  .ant-drawer-content-wrapper {
    width: 370px !important;
    margin-top: 71px;
    border-left: 1px solid $card-border;
    .ant-drawer-content {
      .ant-drawer-wrapper-body {
        background-color: $gray-light;
        .ant-drawer-header {
          background-color: unset;
          border: unset;
          padding: 17px 29px 0 29px;
          .ant-drawer-header-title {
            flex-direction: row-reverse;
            .ant-drawer-close {
              margin: 0 0 0 auto;
              padding-right: 0;
            }
            .ant-drawer-title {
              font-size: 17px;
              font-weight: 700;
              color: $black;
            }
          }
        }
        .ant-drawer-body {
          .ant-spin-nested-loading {
            .ant-spin-container {
              .ant-tabs-content-holder {
                padding: 0px !important;

                .ant-tabs-content {
                  .ant-tabs-tabpane {
                    .ant-row {
                      .dateFilterLayout {
                        width: 500px;
                        .dateFilter {
                          padding-top: 15px;
                          padding-bottom: 3px;
                          color: rgb(186, 186, 186);
                          font-size: smaller;
                          font-weight: bold;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .ant-drawer-body {
          padding: 15px 29px 0 29px;
          .ant-spin-nested-loading {
            .ant-spin-container {
              .ant-tabs-top {
                .ant-tabs-nav {
                  &:before {
                    border: unset;
                  }
                  .ant-tabs-nav-wrap {
                    .ant-tabs-nav-list {
                      .ant-tabs-tab {
                        padding: 0 3px 6px 3px;
                        .ant-tabs-tab-btn {
                          font-size: 16px;
                          font-weight: 400;
                          color: $black-500 !important;
                        }
                        &:nth-child(2) {
                          margin: 0 0 0 20px;
                        }
                        &.ant-tabs-tab-active {
                          .ant-tabs-tab-btn {
                            color: $primary !important;
                          }
                        }
                      }
                    }
                  }
                }
                .ant-tabs-content-holder {
                  padding-bottom: 70px;
                  padding-top: 30px;
                  .ant-tabs-content {
                    .ant-tabs-tabpane {
                      &.ant-tabs-tabpane-active {
                        .ant-row {
                          .ant-image {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: $border-radius-md;
                            margin: 0 7px 7px 0;
                            width: calc((100% - 21px) / 4);
                            height: 72.5px;
                            overflow: hidden;
                            &:nth-child(4n) {
                              margin-right: 0;
                            }
                            img {
                              width: auto;
                              max-height: 72.5px;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .buddieChatAreaLayout {
    .chatEmpty {
      .ant-empty-image {
        height: 45px;
        img {
          height: 45px;
        }
      }
      .ant-empty-description {
        font-size: 18px;
        margin: 10px 0 0 0;
      }
    }
    .chatArea {
      .ant-layout-content {
        .ant-row {
          .buddieHeader {
            min-height: 53px;
            max-height: 53px;
            .buddieHeaderArea {
              img {
                width: 38px;
                height: 38px;
              }
              .buddieTitleArea {
                margin: 2px 0 0 10px;
                .buddieTitle {
                  font-size: 15px;
                  line-height: 15px;
                }
                .buddieReplyTime {
                  font-size: 11px;
                  margin: 0;
                }
              }
            }
            .mediaButtonLayout {
              .btn-secondary-outline {
                height: 28px;
                padding: 5px 9px;
                font-size: 13px;
              }
            }
          }
          .chatBody {
            min-height: calc(100vh - 200px);
            max-height: calc(100vh - 200px);
          }
        }
      }
    }
  }
  .drawerRight {
    .ant-drawer-content-wrapper {
      width: 100% !important;
      .ant-drawer-content {
        .ant-drawer-wrapper-body {
          .ant-drawer-header {
            padding: 17px 20px 0 20px;
          }
          .ant-drawer-body {
            padding: 15px 20px 0 20px;
            .ant-spin-nested-loading {
              .ant-spin-container {
                .ant-tabs-top {
                  .ant-tabs-content-holder {
                    padding-top: 10px;
                    .ant-tabs-content {
                      .ant-tabs-tabpane {
                        &.ant-tabs-tabpane-active {
                          .ant-row {
                            .ant-image {
                              height: 20.7vw;
                              img {
                                min-height: 100%;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// .sendIconChat{
//   display: flex;
//   margin-right: 5px;
//   color: #ffffff;
// }
// .chatAreaLayout {
//   flex-direction: column !important;
//   height: 100%;
// }
// .mediaButton{
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     height: 40px !important;
//     border-radius: 8px !important;
//     margin: 10px;
//     border: solid 1px #939393 !important;
//     width: max-content;
//     min-width: 50px;
//     color: rgb(0, 0, 0) !important;
// }
// .mediaButtonLayout{
//     display: flex;
//     justify-content: flex-end;
// }
// .sendMessageButtonChat{
//   display: flex !important;
//     align-items: center !important;
//     justify-content: center !important;
//     border-color: #ffffff !important;
//     border: none;
//     box-shadow: none;
//     border-radius: 5px !important;
//     background-color: rgb(0,89,147) !important;
//     height: 40px !important;
// }
// .sendMessageText{
//   color: #ffffff !important;
//   font-size: medium;
// }
// .sendMessageButtonChatMobile{
//   display: none !important;
// }

// @media only screen and (max-width: 768px) {

//   .hideBackMobile{
//     display: none !important;
//   }

//   .sendMessageButtonChat{
//     display: none !important;
//   }

//   .chatBubbleInLayout {
//     background-color: rgb(93, 93, 93) !important;
//     padding: 0% !important;
//     height: calc(100vh - 240px);
//   width: 100vw;
//   }

//   sendButtonAttachmentLayout{
//     display: none;
//   }

//   .sendMessageButtonChatMobile{
//     display: flex !important;
//     align-items: center !important;
//     justify-content: center !important;
//     border-color: #ffffff !important;
//     border: none;
//     box-shadow: none;
//     border-radius: 5px !important;
//     background-color: rgb(0,89,147) !important;
//     height: 40px !important;
//   }

//   .inputSendMessageBarLayoutIn{
//     display: flex !important;
//     width: 120% !important;
//     padding-left: 10px;
//     padding-right: 10px;

//     }
//     .buddieHeader {
//       background-color: rgb(222, 220, 220);
//       display: flex;
//       align-items: center;
//       width: 100%;
//       height: 60px;
//       padding: 12px;
//       box-shadow: rgba(0, 0, 0, 0.2) 80px 80px 84px;
//       justify-content: space-between;
//     }

//     .buddiebackgroundImageIn{
//       display: flex;
//       position:absolute;
//       object-fit: cover;

//     width: 100%;
//     height: 100%;
//   }

//   .comment-list{
//   height: inherit;
//     overflow: auto;

// }
// }
