@import '/src/Variable.scss';

// .iframeClass {
//   position: relative !important;
//   z-index: 1000 !important;
// }

.ecuslStyle {
  // height: calc(100vh - 160px);
  // width: 1140px !important;
}

.home_page {
  .jumbotron {
    display: flex;
    align-items: center;
    .jumb-container {
      h2 {
        font-weight: 700;
        font-size: $font-xl;
        line-height: 30px;
        margin: 0;
      }
      p {
        font-weight: 400;
        font-size: 25px;
        line-height: 20px;
        margin: 5px 0 0 0;
      }
    }
  }
  .bannerHeader {
    padding: 24px 0;
  }
}

.jumb-container {
  padding: 20px 120px;
}

.bannerText {
  color: #fafafa;
  font-size: 30px;
}

.bannerSubText {
  color: #fafafa !important;
  font-size: $font-md !important;
}

.bannerHeader {
  padding: 30px;
  padding-left: 50px;
  font-size: 16px;
}

.customTabs {
  div[data-node-key='null'] {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .home_page {
    .jumbotron {
      min-height: 81px;
      .jumb-container {
        padding: 20px;
        h2 {
          font-size: 17px;
          line-height: normal;
          padding-left: 0;
        }
        p {
          line-height: 14px;
        }
      }
    }
  }

  .bannerHeaderTextMobile {
    display: flex !important;
    font-size: 19px !important;
    font-weight: bold !important;
    padding: 0% !important;
  }

  .bannerHeaderSubTextMobile {
    display: flex !important;
    font-size: 12px !important;
    padding: 0% !important;
  }

  .jumb-containerMobile {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .bannerHeaderMobile {
    // display: none;
  }
}

.customTabContainer {
  .ant-tabs-tab {
    margin: 0px 5px !important;
  }

  .ant-tabs-ink-bar {
    background-color: transparent !important;
  }

  .ant-tabs-tab-active .ant-tabs-tab-btn {
    background-color: #f0f5f9 !important;
    color: #005993 !important;
    border-radius: 10px;

    svg {
      color: #005993 !important;
      fill: #005993;
    }

    h4 {
      color: #005993;
    }
  }

  .profileStudentLifeButton {
    border-radius: 10px;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    font-family: Poppins;
    font-size: 16px;
    color: black;

    &:hover {
      background-color: #f0f5f9;
      color: #005993;
      h4 {
        color: #005993;
      }
    }
  }
}

.profileFooter {
  display: flex;
  align-items: center;
  gap: 1em;
  position: absolute;
  right: 10px;
  top: 90px;
  z-index: 999;

  .univiserLogo {
    max-width: 120px;
    max-height: 120px;
  }
}

// @media only screen and (max-width: 1366px) {
//   .iframeClass {
//     width: 1200px !important;
//     margin: 0 auto;
//   }

//   .ecuslStyle {
//     width: 1040px !important;
//     // height: calc(100vh - 123px);
//     // overflow-y: scroll;
//   }
// }

// @media only screen and (max-width: 1023px) {
//   .ecuslStyle {
//     height: calc(100vh - 123px);
//   }
// }

// @media only screen and (max-width: 599px) {
//   .ecuslStyle {
//     height: calc(100vh - 79px);
//     width: 100% !important;
//   }

//   .iframeClass {
//     width: 100% !important;
//   }
// }

// @media only screen and (min-width: 1440px) {
//   .ant-layout-content {
//     // max-width: 1366px;
//   }
// }
