@import '/src/Variable';

.shareDropdownLayout {
  width: 163px !important;
  border-color: #141415;
  border-radius: $border-radius-md;
  .ant-menu {
    margin: 2px !important;
  }

  .shareDropdown {
    .dropDownShareButtonFb {
      height: 30px !important;
    }
    .dropDownShareButtonCopyUrl {
      background-color: transparent !important;
    }
    .margingBottom-4 {
      margin-bottom: 4px;
    }
    .margingBottom-5 {
      margin-bottom: 5px;
    }
    .margingBottom-8 {
      margin-bottom: 8px !important;
    }
  }
}

.badgesPopover {
  width: 600px;

  img {
    width: 48px;
    height: 48px;
    border-radius: 100%;
  }
  .badgesPopoverBadge {
    text-align: center;
    margin: 0 4px;
    display: inline-block;
    min-width: 100px;
  }
}

.viewProfile {
  .ant-row {
    .backButton {
      margin: 20px 0 0 120px;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $black;
      border: unset;
      z-index: 2;
      svg {
        font-size: 24px;
      }
    }
    .bannerWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 215px;
      // overflow: hidden;
      position: relative;
      z-index: 1;
      .bannerImgWrapper {
        width: 100%;
        height: 215px;
        overflow: hidden;
        .backroundCover {
          width: 100%;
          // object-fit: cover;
        }
      }
      &:after {
        content: '';
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
        width: 100%;
        position: absolute;
        height: 110px;
        bottom: 0;
      }
      .avatarRow {
        display: flex;
        align-items: center;
        margin: -110px 50px 0 50px;
        position: absolute;
        left: 70px;
        bottom: -55px;
        z-index: 2;
        .profileImage {
          width: 170px;
          height: 170px;
          border: 3px solid $white;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 28px 0 0;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .profileDetails {
          display: flex;
          align-items: center;
          position: relative;
          .profileName {
            font-size: $font-xxl;
            font-weight: 700;
            color: $white;
            text-shadow: 2px 2px 5px $black;
          }
          .ant-ribbon-wrapper {
            .profileBadge {
              background-color: $green;
              border-radius: $border-radius-sm;
              position: unset;
              margin: 0 0 0 7px;
              .ant-ribbon-text {
                color: $white;
                font-size: 12px;
              }
            }
          }

          .playButton {
            margin-left: 3px;
            border-radius: $border-radius-sm;
            border-color: white;
            background-color: transparent;
            color: white;
            // width: -webkit-fill-available;
          }
        }
      }
    }
  }

  .profileTab {
    margin: 0 0 0 0;
    .ant-row {
      .ant-col {
        .tabArea {
          background-color: $gray-bg;
          .ant-tabs-nav {
            padding: 0 120px 0 320px;
            background-color: $gray-light;
            height: 75px;
            margin: 0 0 37px 0;
            .ant-tabs-nav-wrap {
              position: relative;
              z-index: 2;
              .ant-tabs-nav-list {
                .ant-tabs-tab {
                  padding: 16px 10px;
                  .ant-tabs-tab-btn {
                  }
                  &.ant-tabs-tab-active {
                    .ant-tabs-tab-btn {
                      color: #005993;
                      font-family: Roboto;
                      font-size: 20px;
                      font-style: normal;
                      font-weight: 700;
                      line-height: normal;
                    }
                  }
                }
                .ant-tabs-ink-bar {
                  background-color: #005993;
                }
              }
            }

            .ant-tabs-extra-content {
              .btnArea {
                display: flex;
                align-items: center;
                margin-right: 40px;
                .btnShareProfile {
                  border: 1px solid $primary;
                  border-radius: $border-radius-sm;
                  padding: 0 15px;
                  height: 45px;
                  display: flex;
                  align-items: center;
                  margin: 0 12px 0 0;
                  font-size: 15px;
                  font-weight: 700;
                  svg {
                    margin: 0 6px 0 0;
                    margin-bottom: 2px;
                  }
                  .anticon {
                    svg {
                      margin: 0 0 0 5px;
                    }
                  }
                }
                .sendMsgBtn {
                  border: 1px solid $primary;
                  border-radius: $border-radius-sm;
                  padding: 0 15px;
                  height: 45px;
                  display: flex;
                  align-items: center;
                  font-size: 15px;
                  font-weight: 700;
                  svg {
                    margin: 0 5px 0 0;
                  }
                }
              }
            }
          }
          .ant-tabs-content-holder {
            padding: 0 90px 50px 90px;
            .ant-tabs-content {
              .ant-tabs-tabpane {
                .tabSubTopic {
                  color: #000;
                  font-family: Roboto;
                  font-size: 21px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: normal;
                }
                .LeftCol {
                  border: 0.75px solid $border-gray;
                  border-radius: $border-radius-md;
                  background-color: $white;
                  max-width: 650px;
                  max-height: 600px;
                  flex-shrink: 0;
                  overflow: auto;

                  .ratedColumn {
                    padding: 20px 20px 20px 0;

                    .rateTitle {
                      margin-left: 52px;
                      color: #000;
                      font-family: Roboto;
                      font-size: 25px;
                      font-style: normal;
                      font-weight: 700;
                      line-height: normal;
                    }

                    .allRatings {
                      // margin-left: 52px
                      padding-left: 20px;
                    }

                    .flexRow {
                      display: flex;
                      border: 0.75px solid $orange;
                      background-color: $orange-light;
                      border-radius: $border-radius-md;
                      // width: 610px;
                      height: 51px;
                      flex-shrink: 0;
                      margin-left: 20px;
                      margin-right: 20px;

                      .buddyReplyTime {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #774a00;
                        font-family: Roboto;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        padding-left: 16px;
                      }
                    }
                  }
                  .buddyProfileTable {
                    padding: 0 32px 10px 34px;
                    .tableInner {
                      .ant-descriptions {
                        .ant-descriptions-view {
                          table {
                            tbody {
                              tr {
                                min-height: 56px;
                                &:nth-last-child(1) {
                                  border-bottom: unset;
                                }
                                td {
                                  vertical-align: middle;
                                  padding: 10px 0;
                                  .ant-descriptions-item-container {
                                    border-bottom: 1px solid $gray-light;
                                    padding: 2px 0;

                                    .ant-descriptions-item-label {
                                      width: 165px;
                                      color: #000;
                                      font-family: Roboto;
                                      font-size: 16px;
                                      font-style: normal;
                                      font-weight: 500;
                                      line-height: normal;
                                      padding-left: 0;
                                      padding-bottom: 2px;
                                      &:after {
                                        display: none;
                                      }
                                    }
                                    .ant-descriptions-item-content {
                                      padding-right: 16px;
                                      color: #000;
                                      font-family: Roboto;
                                      font-size: 16px;
                                      font-style: normal;
                                      font-weight: 400;
                                      line-height: normal;

                                      .lang {
                                        &:after {
                                          content: ',';
                                          margin-right: 5px;
                                        }
                                        &:nth-last-child(1) {
                                          &:after {
                                            display: none;
                                          }
                                        }
                                      }
                                      .hobbyIn {
                                        display: flex;
                                        align-items: center;
                                        .hobbyIcon {
                                          display: flex;
                                          img {
                                            height: 25px;
                                            margin-right: 2px;
                                          }
                                          &:after {
                                            content: ',';
                                            margin-right: 5px;
                                          }
                                          &:nth-last-child(1) {
                                            &:after {
                                              display: none;
                                            }
                                          }
                                        }
                                      }
                                      .userLangugeImg {
                                        display: flex;
                                        align-items: center;
                                        img {
                                          height: 16px;
                                          margin-right: 5px;
                                        }
                                      }
                                    }
                                  }

                                  .ant-descriptions-item-container.last-child {
                                    border: unset;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                .sendMsgBtnSp {
                  display: none;
                }
                .rightCol {
                  max-width: 650px;
                  max-height: 600px;
                  border-radius: $border-radius-md;
                  border: 0.75px solid #c6c6c6;
                  background: #fff;
                  overflow: auto;
                  padding: 16px;

                  .cardContainer {
                    display: flex;
                    justify-content: center;
                    // align-items: center;
                    flex-wrap: wrap;
                    gap: 16px;
                    max-height: 90% !important;
                    overflow-y: auto;
                    // margin: 16px 0;

                    .card {
                      position: relative;
                      width: 300px; // Adjust the width of each card as needed
                      height: 171px; // Take full height of the container
                      border-radius: $border-radius-md;
                      border: 0.75px solid #c6c6c6;

                      .cardData {
                        border-radius: $border-radius-md;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        padding: 8px 16px;
                        width: 100%; // Make sure the content spans the entire width
                        display: flex;
                        flex-direction: column;
                        align-items: baseline;
                        background: linear-gradient(to top, rgba(0, 0, 0, 0.9) 40%, transparent 100%);
                        color: #fff;
                      }
                    }

                    .card-iframe {
                      flex-grow: 1;
                      width: 200px !important;
                      height: 140px !important;
                    }
                  }

                  .card-row {
                    display: flex;

                    .card-column {
                      flex: 1;
                      padding: 20px;
                      width: 300px;
                      height: 171px;
                      flex-shrink: 0;
                      border-radius: $border-radius-md;
                      background: linear-gradient(180deg, rgba(0, 0, 0, 0.01) 45.84%, #000 100%);
                      margin: 20px 10px 20px 20px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.d-none {
  display: none !important;
}

// @media screen and (max-width: 1440px) {
//   .rightCol .cardContainer .card {
//     width: 260px !important;
//   }
// }

@media screen and (max-width: 1366px) {
  .viewProfile {
    .profileTab {
      .ant-col {
        .tabArea {
          .ant-tabs-content-holder {
            margin-top: -20px !important;
            padding: 0 20px 20px 20px;

            .ant-tabs-content {
              .ant-tabs-tabpane {
                .LeftCol {
                  border: 0.75px solid $border-gray;
                  border-radius: $border-radius-md;
                  background-color: $white;
                  max-width: 600px;
                  max-height: 475px;
                  flex-shrink: 0;
                }

                .rightCol {
                  max-width: 600px;
                  max-height: 475px;
                  border-radius: $border-radius-md;
                  border: 0.75px solid #c6c6c6;
                  background: #fff;
                  overflow: auto;

                  .cardContainer {
                    max-height: 90% !important;
                    .card {
                      position: relative;
                      width: 230px !important; // Adjust the width of each card as needed
                      height: 171px; // Take full height of the container
                      border-radius: 6px;
                      border: 0.75px solid #c6c6c6;

                      .cardData {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        padding: 16px; // Add padding for better readability
                        width: 100%; // Make sure the content spans the entire width
                        display: flex;
                        flex-direction: column;
                        align-items: baseline;
                        color: #fff;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .viewProfile {
    .ant-row {
      .backButton {
        width: 24px;
        min-width: 24px;
        height: 24px;
        margin: 13px 0 0 20px;
        svg {
          font-size: 18px;
        }
      }
      .bannerWrapper {
        height: 92px;
        .bannerImgWrapper {
          height: 92px;
        }
        .avatarRow {
          margin: -110px 50px 0 20px;
          left: 0;
          .profileImage {
            width: 87px;
            height: 87px;
            border: 2px solid #fff;
            margin-right: 12px;
          }
          .profileDetails {
            margin: 11px 0 0 0;
            flex-direction: column;
            align-items: flex-start;
            .profileName {
              font-size: 17px;
              color: $black;
              text-shadow: unset;
              margin: 15px 0 0 0;
            }
            .ant-ribbon-wrapper {
              .profileBadge {
                margin: 0;
                background-color: unset;
                border: 1px solid $green;
                min-width: 42px;
                height: 14px;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                .ant-ribbon-text {
                  font-size: 8px;
                  color: $green;
                }
              }
            }

            .playButton {
              margin-left: 3px;
              border-radius: 5px;
              border-color: white;
              background-color: red;
              color: white;
              // width: -webkit-fill-available;
            }
          }
        }
      }
    }
    .profileTab {
      .ant-row {
        .ant-col {
          .tabArea {
            // background: $white !important;
            .ant-tabs-nav {
              margin: 0 0 20px 0;
              padding: 0 0 0 20px;
              align-items: flex-start;
              height: 110px;
              background-color: $white !important;
              .ant-tabs-nav-wrap {
                z-index: 0;
                .ant-tabs-nav-list {
                  z-index: 0;
                  padding-top: 60px;
                  .ant-tabs-tab {
                    height: 72px;
                    &.ant-tabs-tab-active {
                      .ant-tabs-tab-btn {
                        font-size: 14px;
                        // display: none !important;
                      }
                    }
                  }
                }
              }
              .ant-tabs-extra-content {
                .btnArea {
                  margin: 14px 0 0 0;
                  .sendMsgBtn {
                    display: none;
                  }
                  .btnShareProfile {
                    border: unset;
                    margin-right: 0;
                    height: 20px;
                    .dropDownIcon {
                      span {
                        display: none;
                      }
                    }
                  }
                }
              }
            }
            .ant-tabs-content-holder {
              margin-top: -20px !important;
              padding: 0 10px 20px 10px !important;
              .ant-tabs-content {
                .ant-tabs-tabpane {
                  .LeftCol {
                    // width: 275px;
                    .ratedColumn {
                      padding: 12px 12px 12px 12px;
                      .flexRow {
                        display: flex;
                        border: 0.75px solid $orange;
                        background-color: $orange-light;
                        border-radius: 6px;
                        width: unset;
                        height: 51px;
                        flex-shrink: 0;
                        margin-left: 20px;
                        margin-right: 20px;

                        .buddyReplyTime {
                          background-color: $gray-bg;
                          border-radius: 5px;
                          width: 100%;
                          font-size: 12px !important;
                          font-weight: normal !important;
                        }
                      }
                    }
                    .buddyProfileTable {
                      padding: 0 10px 3px 10px;
                      .tableInner {
                        .ant-descriptions {
                          .ant-descriptions-view {
                            table {
                              tbody {
                                tr {
                                  min-height: 34px;
                                  td {
                                    padding: 10px 0;
                                    .ant-descriptions-item-container {
                                      .ant-descriptions-item-label {
                                        font-size: 12px;
                                        padding-left: 6px;
                                        margin-right: 6px;
                                        width: 105px;
                                      }
                                      .ant-descriptions-item-content {
                                        font-size: 12px;
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  .sendMsgBtnSp {
                    background-color: $primary;
                    width: 100%;
                    height: 26px;
                    color: $white;
                    border-radius: $border-radius-sm;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 12px;
                    font-weight: 400;
                    margin: 18px 0 0 0;
                    svg {
                      margin: 0 5px 0 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .ratingSection {
    margin-top: 30px;
  }
}

.profileEarnedBadges {
  position: absolute;
  top: 0.9rem;
  left: 20rem;
  display: flex;
  z-index: 900;

  img {
    width: 48px;
    height: 48px;
    border-radius: 100%;
    margin-right: -12px;
    -webkit-transition: all ease 0.3s 0s;
    transition: all 0.3s ease 0s;
    border: 1px solid $white;
  }
  img:nth-child(1) {
    z-index: 4;
  }
  img:nth-child(2) {
    z-index: 3;
  }
  img:nth-child(3) {
    z-index: 2;
  }
  .moreBtn {
    z-index: 1;
    width: 48px;
    height: 48px;
    border: 1px solid #dddddd;
    background-color: $white;
    color: #777777;
    font-weight: bold;
    cursor: pointer;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0;
  }
}
@media only screen and (max-width: 420px) {
  .profileEarnedBadges {
    top: 3rem;
    left: 7rem;
    img,
    .moreBtn {
      width: 36px;
      height: 36px;
    }
  }
  .badgesPopover {
    width: 320px;
  }
}

.viewProfile .profileTab .ant-row .ant-col .tabArea .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab {
  &.ant-tabs-tab-active .ant-tabs-tab-btn,
  &:hover .ant-tabs-tab-btn {
    color: var(--theme-color);
  }
}

.ant-tabs-ink-bar {
  background: var(--theme-color) !important;
}
