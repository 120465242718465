@import '/src/Variable.scss';

.slPcMain {
  border-radius: $border-radius-md !important;
  font-family: $font-primary;
  border: 1px solid $card-border !important;

  .ant-card-body {
    padding-top: 16px;
  }

  .slPcTitle {
    border: unset;
    font-weight: bold;
    font-size: 20px;
  }

  .slPcSub {
    margin-bottom: 16px;
    padding-bottom: 16px;
    border: 0;
    border-bottom: 1px solid $card-border;

    .ant-card-body {
      padding: 0;
    }
    .slPcDescription {
      font-weight: 400;
      word-wrap: break-word;
      overflow-wrap: break-word;
    }

    .slPcImageRow {
      margin-bottom: 5px;

      .slPcImage {
        border-radius: $border-radius-md;
      }
    }

    .slMpLikeText {
      color: $gray-text;
    }

    .slPcFooter {
      display: flex;
      justify-content: space-between;
      .slPcFooterReadMore {
        color: $primary-tab;
        font-weight: bold;
        cursor: pointer;
      }

      .slPcFooterLikes {
        font-size: 12px;
        color: $gray-text;
      }

      .slPcFooterShares {
        font-size: 12px;
        color: $gray-text;
      }
    }
  }

  .slPcSub:nth-child(2) {
    margin-top: 24px;
    margin-bottom: 0;
    background: $gray-bg;
    outline: 10px solid $gray-bg;
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 24px;
  }

  .slPcSub:last-child {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}
