.ant-dropdown {
  z-index: 1000 !important;
}

.logoutNavBarButtonMobile{
  color: aqua !important;
display: flex !important;
align-items: center !important;
padding: 0% !important;
border-color: #ffffff !important;
width: 100%;
box-shadow: none !important;
}

.logoutNavBarTextMobile{
  padding-left: 5px;
  color: #828080;
}

.logoutIcon{
  color: #828080;
}