.ambassadors__form-container {
  padding: 30px;
  width: 40%;
  margin: 30px auto;
  border-radius: 5px;
  border: solid 1px #acacac;

  .ambassadors__title-container {
    text-align: center;

    .ambassadors__form-heading {
      font-size: 22px;
      font-weight: 600;
      line-height: 35px;
      background: linear-gradient(109.6deg, rgba(0, 0, 0, 0.93) 11.2%, rgb(63, 61, 61) 78.9%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .form__container {
    display: flex;

    .form__input {
      border-radius: 5px;
    }

    .form__item {
      margin-bottom: 5px;
    }

    .phone__input {
      height: 40px;
    }

    .submit__button {
      margin-top: 12px;
      float: right;
      border-radius: 5px;
      // background: linear-gradient(-225deg, #005993 0%, #5753c9 48%, #0688dc 100%);
      border: none;
      &:hover {
        opacity: 0.9;
        transition: 0.3s;
      }
    }

    .dropdown {
      .ant-dropdown-menu {
        max-width: max-content;
      }
    }
  }

  .ant-input {
    &:placeholder-shown,
    &:hover,
    &:focus {
      // font-size: 14px;
      outline: none;
      box-shadow: none;
      border-color: #d9d9d9;
    }
  }
}

.subtitle {
  color: #787878;
  font-size: 14px;
  font-weight: 400;
}

@media (max-width: 1400px) {
  .ambassadors__form-container {
    width: 40%;
  }
}

@media (max-width: 1300px) {
  .ambassadors__form-container {
    width: 45%;
  }
}

@media (max-width: 1150px) {
  .ambassadors__form-container {
    width: 50%;
  }
}

@media (max-width: 1000px) {
  .ambassadors__form-container {
    width: 55%;
  }
}

@media (max-width: 1100px) {
  .ambassadors__form-container {
    overflow: hidden;
    .form__container {
      .submit__button {
        width: 100%;
      }
    }
  }
}

@media (max-width: 900px) {
  .ambassadors__form-container {
    width: 60%;
  }
}

@media (max-width: 800px) {
  .ambassadors__form-container {
    width: 70%;
  }
}

@media (max-width: 700px) {
  .ambassadors__form-container {
    width: 80%;
    .ambassadors__title-container {
      .ambassadors__form-heading {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }
}

@media (max-width: 600px) {
  .ambassadors__form-container {
    width: 100%;
    padding: 20px;
    margin: 10px auto;
    border: solid 1px #acacac;

    // .form__container {
    //   .submit__button {
    //     margin-top: 10px;
    //     width: 100%;
    //     height: 45px;
    //   }
    // }

    .ambassadors__title-container {
      .ambassadors__form-heading {
        font-size: 18px;
        line-height: 25px;
      }
    }
  }
}
