.lazy-comp-placeholder {
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.5);
  transition: opacity 1s ease-in-out, backdrop-filter 1s ease-in-out;
}
