@import "/src/Variable.scss";

.studentLife {
  padding: 30px 120px;
  min-height: 86.2vh !important;

  .mainTitleRowWordpress {
    margin-bottom: 20px;
    .mainTitleWordpress {
      font-family: Roboto;
      font-size: 40px;
      font-weight: 700;
      line-height: 64px;
      letter-spacing: 0em;
      text-align: center;
    }
  }

  .studentLifeSearchRow {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .slSearch {
      width: 100%;
      height: 50px;
      border-radius: $border-radius-md;
    }

    .slFilter {
      color: #d9d9d9;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background-color: unset;
      border: unset;
    }
  }

  .studentLifePostCardRow {
    margin-top: 10px;

  .custom-empty {
    margin-top: 20px;
    color: #555;
    font-size: 16px;
    text-align: center;
}
  }
}

@media only screen and (max-width: 1024px) {
  .studentLife {
    padding: 30px 60px;
  }
}

@media only screen and (max-width: 720px) {
  .studentLife {
    padding: 20px;
    .studentLifeSearchRow {
      .slSearch {
        height: 50px;
        border-radius: 10px !important;
      }

      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-color: unset;
        border: unset;
      }
    }
  }
}
