@import '/src/Variable.scss';

body {
    font-family: 'Roboto', sans-serif !important;
    font-weight: 400;
}
.container {
    padding: 0 20px;
}
.ant-layout {
    background-color: unset !important;
}
// Pagination
.ant-pagination {
    margin: 40px 0 40px 0 !important;
    display: flex;
    justify-content: center;
}
// Button
.btn {
    &.btn-primary {
        display: flex;
        align-items: center;
        background-color: $primary;
        border: 1px solid $primary;
        border-radius: $border-radius-md;
        padding: 6px 9px;
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        color: $white;
        min-height: 42px;
        &:hover {
            background-color: $primary;
            border: 1px solid $primary;
            color: $white;
            opacity: 0.9;
        }
        svg {
            margin: 0 5px 0 0;
        }
    }
    &.btn-secondary-outline {
        background-color: $white;
        border: 1px solid $black;
        border-radius: $border-radius-md;
        padding: 6px 9px;
        font-size: 15px;
        line-height: 18px;
        font-weight: 400;
        color: $black;
        &:hover {
            border: 1px solid $black;
            color: $black;
            opacity: 0.6;
        }
    }
}
.btn-lg {
    height: auto !important;
    padding: 8px 16px !important;
}
// Image Preview Light Box
.ant-image-preview-root {
    .ant-image-preview-mask {
        background-color: rgba($black, 0.85);
    }
}

.ReactTags__tagInput .ReactTags__tagInputField{

    &:focus {
        outline: unset !important;
    }
}

.yourMobileNumberInput .PhoneInputInput {
    &:focus {
        outline: unset !important;
    }
}

.text-right {
    text-align: right;
}

.mt-sm {
    margin-top: 4px;
}
.mt-md {
    margin-top: 8px;
}
.mt-lg {
    margin-top: 16px;
}
.mt-xl {
    margin-top: 20px;
}
.mb-sm {
    margin-bottom: 4px;
}
.mb-md {
    margin-bottom: 8px;
}
.mb-lg {
    margin-bottom: 16px;
}
.mb-xl {
    margin-bottom: 20px;
}
.text-center {
    text-align: center;
}