.buddy__form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  margin-bottom: 40px;

  .icon__container {
    text-align: center;

    .icon {
      color: #7e7e7e;
      font-size: 54px;
    }
  }

  .buddy__unavailable-text {
    font-size: 22px;
    text-align: center;
    line-height: 35px;
    // color: #7e7e7e;
    font-weight: 500;
    background: linear-gradient(to right, #868f96 0%, #596164 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

@media (max-width: 700px) {
  .buddy__form-container {
    .icon__container {
      .icon {
        font-size: 46px;
      }
    }
    .buddy__unavailable-text {
      font-size: 18px;
      line-height: 25px;
    }
  }
}
