@import '/src/Variable.scss';

.slPostDetailMainRow {
  .slPostDetailMainColumn {
    .slPostDetailNavRow {
      .slPostDetailBackButtonColumn {
        .slPostDetailBackButton {
          margin: 20px 0 0 120px;
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $black;
          border: unset;
          z-index: 2;
          svg {
            font-size: 24px;
          }
        }
      }

      .slPostDetailBackButtonColumn {
        border: none !important;
        z-index: 2;
        svg {
          font-size: 24px;
        }
      }
    }

    .slPostDetailRow {
      padding: 90px 120px 60px 120px;
      .slPostDetailGalleryColumn {
        .slPostDetailGalleryMainImage {
          margin-bottom: 10px;
          width: 100%;
          height: 500px !important;
          // min-height: 500px !important;
          // object-fit: contain;
        }

        .ant-card {
          border-radius: $border-radius-md;
          .ant-card-cover {
            img {
              border-radius: $border-radius-md $border-radius-md 0 0;
            }
          }
        }

        .slMpDescription {
          margin-top: 10px;
        }

        .slPostDetailGalleryMainDiv {
          background: #f1f1f1;
          border-radius: $border-radius-md;

          // .slPostDetailGalleryMainImage {
          //   margin-bottom: 10px;
          //   width: 100%;
          //   max-height: 500px !important;
          //   min-height: 500px !important;
          //   object-fit: contain;
          // }

          .slPostDetailGalleryDiv {
            padding: 0 0 0 20px;
          }

          .slPostDetailGalleryDivider {
            padding-left: 10px;
            padding-right: 10px;
          }
        }

        // .slPostDetailGalleryImage {
        //   width: 150px;
        //   height: 150px;
        //   object-fit: cover;
        //   border-radius: $border-radius-md;
        // }

        .slPostDetailGalleryLikeButton {
          border: unset;
          color: #b9b9b9;
        }

        .slPostDetailGalleryLikeText {
          color: #b9b9b9;
        }

        .slPostDetailGalleryShareText {
          color: #b9b9b9;
        }

        // .slick-initialized .slick-slide {
        //   width: 200px !important;
        // }
      }

      .slPostDetailDetailCardColumn {
        .slPostDetailCard {
          border-radius: $border-radius-md;

          .slPostDetailCardMeta {
            display: flex;
            margin-bottom: 8px;

            .slPostDetailCardMetaDetail {
              margin-left: 10px;
              .slPostDetailCardMetaName {
                color: $black;
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
              }

              .slPostDetailCardMetaCourse {
                color: $black;
              }

              .slPostDetailCardMetaProgramme {
                color: $primary-tab;
                line-height: 16px;
              }
            }
          }
          .slPostDetailCardStatRow {
            margin-top: 10px;
            margin-bottom: 10px;
            .slPostDetailCardStatColumn {
              .slPostDetailCardStatCard {
                border-radius: 10px;
                background: #f1f1f1;
                height: 110px;

                .ant-card-body {
                  padding: 12px;
                }

                .slPostDetailCardStatCardTitle {
                  color: $black-500;
                  margin-bottom: 4px;
                }

                .slPostDetailCardReplyTimeStatCardDesc {
                  color: $black;
                }

                .slPostDetailCardStatCardDesc {
                  color: $black;

                  .interests {
                    height: 25px;
                    margin-right: 2px;
                  }
                }
              }
            }
          }

          .slPostDetailCardHelloButtonRow {
            .slPostDetailCardHelloButtonColumn {
              .slPostDetailCardHelloButton {
                width: 100%;
                border-radius: 10px;
                color: #fff;
              }
            }
          }

          .slPostDetailCardViewPostRow {
            .slPostDetailCardViewPostColumn {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 10px;
              color: $primary-tab;
              font-weight: 500;
              color: $primary-tab;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1130px) {
  .slPostDetailMainRow {
    .slPostDetailMainColumn {
      .slPostDetailRow {
        .slPostDetailGalleryColumn {
          .slPostDetailGalleryMainImage {
            height: 450px !important;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .slPostDetailMainRow {
    .slPostDetailMainColumn {
      .slPostDetailRow {
        padding: 90px 60px 60px 60px;
      }
      .slPostDetailNavRow {
        .slPostDetailBackButtonColumn {
          .slPostDetailBackButton {
            margin: 20px 0 0 60px;
          }
        }
      }
      .slPostDetailRow {
        .slPostDetailDetailCardColumn {
          .slPostDetailCardStatRow {
            .slPostDetailCardStatColumn {
              margin-bottom: 12px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .slPostDetailMainRow {
    .slPostDetailMainColumn {
      .slPostDetailRow {
        .slPostDetailGalleryColumn {
          .slPostDetailGalleryMainImage {
            height: 400px !important;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 720px) {
  .slPostDetailMainRow {
    .slPostDetailMainColumn {
      .slPostDetailNavRow {
        .slPostDetailBackButtonColumn {
          .slPostDetailBackButton {
            margin: 20px 0 0 20px;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $black;
            border: unset;
            z-index: 2;
            svg {
              font-size: 24px;
            }
          }
        }
      }

      .slPostDetailRow {
        padding: 90px 20px 60px 20px;
        .slPostDetailGalleryColumn {
          .slPostDetailGalleryMainImage {
            height: 300px !important;
          }
        }
        .slPostDetailDetailCardColumn {
          .slPostDetailCardStatRow {
            .slPostDetailCardStatColumn {
              .slPostDetailCardStatCard {
                .slPostDetailCardStatCardTitle {
                  color: $black-500;
                  margin-bottom: 4px;
                  font-size: 13px;
                  text-align: center;
                }

                .slPostDetailCardReplyTimeStatCardDesc {
                  text-align: center;
                }

                .slPostDetailCardStatCardDesc {
                  color: $black;
                  text-align: center;
                  .interests {
                    height: 22px !important;
                    text-align: center;
                  }
                }

                .languages_list {
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }
  }
}
