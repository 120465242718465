@import '/src/Variable.scss';

.ant-modal {
  top: 10px !important;
}

.titleVideoModal {
  display: flex;
  align-items: center;

  .profileImage {
    width: 80px;
    height: 80px;
    border: 3px solid $white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 18px 0 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .titleText {
    // margin-left: 2px;
  }

  .titleSubText {
    color: #616161;
    margin-top: -10px;
  }
}

.videoSection {
  border-radius: 5px;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  .questionButton {
    border-radius: 5px;
    margin-left: auto;

    .buttonContent {
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;

      .buttonText {
        margin-left: 5px;
      }
    }
  }
}

@media only screen and (max-width: 420px) {
  .titleVideoModal {
    display: flex;
    align-items: center;

    .profileImage {
      // width: 60px;
      height: 50px;
      border: 3px solid $white;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 10px 0 0;
      // img {
      //   width: 100%;
      //   height: 100%;
      //   object-fit: cover;
      // }
    }

    .titleText {
      // margin-left: 2px;
      font-size: 11px;
    }

    .titleSubText {
      color: #616161;
      margin-top: -10px;
    }
  }

  .videoSection {
    border-radius: 5px;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    .questionButton {
      border-radius: 5px;
      width: 150px;
      margin-left: auto;

      .buttonContent {
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;

        .buttonText {
          margin-left: 5px;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .titleVideoModal {
    display: flex;
    align-items: center;

    .profileImage {
      width: 80px;
      height: 80px;
      border: 3px solid $white;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 18px 0 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .titleText {
      // margin-left: 2px;
    }

    .titleSubText {
      color: #616161;
      margin-top: -10px;
    }
  }

  .videoSection {
    border-radius: 5px;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    .questionButton {
      border-radius: 5px;
      margin-left: auto;

      .buttonContent {
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;

        .buttonText {
          margin-left: 5px;
        }
      }
    }
  }
}
